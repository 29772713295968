import { setService } from "../Services/getTextLawPDP";

export const getReportDP = async () => {
  return await setService().get("ObtenerListadoAutorizacionDatos");
};

export const getObtenerTratamientoDatosPersonales = async (
  idToken: string,
  appName: string
) => {
  return await setService().post(
    "ObtenerDeclaracionTratamientoDatosPersonales",
    {
      usuarioId: idToken,
      nombreAplicacion: appName,
    }
  );
};
export const getCrearDeclaracionTdp = async (data: any, token: string) => {
  return await setService(token).post("CrearDeclaracionTdp", {
    ...data,
  });
};

export const getObtenerAdministradores = async () => {
  return await setService().get("ObtenerAdministradores");
};

export const getObtenerListadoAplicaciones = async () => {
  return await setService().get("ObtenerListadoAplicaciones");
};

export const getObtenerListadoDeclaracionTdp = async (
  idToken: string,
  appName: string
) => {
  return await setService().post("ObtenerListadoDeclaracionTdp", {
    usuarioId: idToken,
    nombreAplicacion: appName,
  });
};
export const getCrearAdministrador = async (
  idToken: string,
  appName: string,
  data: any
) => {
  try {
    return await setService(idToken).post("CrearAdministrador", {
      usuarioId: idToken,
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setDeleteAdmin = async (idToken: string, data: any) => {
  return await setService(idToken).delete("EliminarAdministrador", {
    data: { ...data },
  });
};

export const getEstaUsuarioAutorizadoIngresarAplicacion = async (
  idToken: string,
  appName: string
) => {
  return await setService().post("EstaUsuarioAutorizadoIngresarAplicacion", {
    usuarioId: idToken,
    nombreAplicacion: appName,
  });
};

export const setGuardarAutorizacionDatos = async (
  token?: string,
  idToken?: string,
  appName?: string,
  dataSave?: any
) => {
  return await setService(token).post("CrearAutorizacionDatos", {
    usuarioId: idToken,
    nombreAplicacion: appName,
    ...dataSave,
  });
};

export const getValidAdmin = async (
  userId: string,
  nameApp: string,
  token: string
) => {
  return await setService(token).post("ExisteAdministrador", {
    usuarioId: userId,
    nombreAplicacion: nameApp,
  });
};