import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

const withAuthentication = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  return (props: any) => {
    const { isAuth } = props;

    const checkTokenValidity = () => {
      const allKeys = Object.keys(localStorage);
      const idTokenKeys = allKeys?.filter((key) => key.endsWith("idtoken"));
      const tokenFromSessionStorage = idTokenKeys?.map((key) => localStorage?.getItem(key))[0];
      return tokenFromSessionStorage !== null && tokenFromSessionStorage !== undefined && tokenFromSessionStorage !== '';
    };

    const [showAlert, setShowAlert] = useState(!checkTokenValidity());

    useEffect(() => {
      setShowAlert(!checkTokenValidity());
    }, [setShowAlert, isAuth, props]);

    return !showAlert && isAuth ? (
      <Component {...props} />
    ) : (
      <Alert show={showAlert} variant="warning" onClose={() => setShowAlert(false)} dismissible>
        <p>Por favor, inicia sesión para acceder a esta página.</p>
      </Alert>
    );
  };
};
export default withAuthentication;



