import CircleIcon from "@mui/icons-material/Circle";
import { Col, Container, Row } from "react-bootstrap";
export const IconState = ({ label, color }: typeComponents): JSX.Element => {
  return (
    <Container style={{margin:'-26%'}}>
      <Row style={{display:'flex', alignContent:'center', flexWrap:'wrap'}}>
        <Col xs={1} md={1}>
          <CircleIcon style={{ color: color, fontSize: "20px" }} />
        </Col>
        <Col xs={7} md={7}>{label}</Col>
      </Row>
    </Container>
  );
};
interface typeComponents {
  label: string;
  color: string;
}
