import "../../textLaw.css";
import "../../css/viewPDP.css";
import imgLogoGreen from "../../Assets/logoGreen.png";
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useState } from "react";
import { ApproveDeclineTDP } from "./ApproveDeclineTDP";
import { InterfaceTextLaw } from "../../Interface/InterfaceTextLaw";
import { ModalCustom } from "../../Components/ModalCustom/ModalCustom";
import { ForceLoadPage, getOriginURI } from "../../Utils/ForceLoadPage";
import { setGuardarAutorizacionDatos } from "../../Providers/GetTextLaw";

export const ViewPDP = ({
  text,
  dataUser,
  logout,
  version,
  nameApp,
  loading = () => {},
}: InterfaceTextLaw): JSX.Element => {
  const saveApprove = ({ approve }: InterfaceApprove) => {
    loading(true);
    const dataSave = {
      commondRequestDto: {
        usuarioId: dataUser.userInfo.id,
        nombreAplicacion: `${getOriginURI()}`,
      },
      nombreUsuario: dataUser.displayName,
      aproboTratamiento: approve,
      versionTratamientoDatos: version,
      email: dataUser.email,
    };
    setGuardarAutorizacionDatos(
      dataUser.getTokent,
      dataUser.idToken,
      nameApp,
      dataSave
    )
      .then((res) => {
        ForceLoadPage({ url: "/" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [OpeneModal, setOpeneModal] = useState(false);
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <CardGroup>
            <Card key={"TextBody"}>
              <Card.Header
                style={{ background: "rgb(14, 12, 90)", color: "white" }}
              >
                <Card.Title>
                  Declaración de tratamiento de la información personal en
                  Ecopetrol S.A.
                </Card.Title>
              </Card.Header>
              <Card.Body style={{ background: "#f1f2f2" }}>
                <Form.Control
                  as="textarea"
                  style={{
                    margin: "0px 0px",
                    padding: "30px",
                    height: "55vh",
                    background: "#F1F2F2",
                    resize: "none",
                    fontSize: "12px",
                  }}
                  disabled
                  defaultValue={text}
                />
              </Card.Body>
            </Card>
            <Card key={"FormBody"} className={"mb-0"}>
              <Card.Img
                variant="top"
                src={imgLogoGreen}
                style={{ padding: "40px" }}
              />
              <Card.Body>
                <Container style={{ margin: "1px", marginTop: "12%" }}>
                  <Row>
                    <Col xs={"12"}>
                      <span style={{ fontSize: "12px" }}>
                        ¿Acepta la política de protección de tratamiento de
                        datos personales?
                      </span>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }}>
                      <Button
                        size={"sm"}
                        variant="primary"
                        onClick={() => {
                          saveApprove({ approve: true });
                        }}
                      >
                        Aceptar
                      </Button>
                    </Col>
                    <Col xs={{ span: 1, offset: 3 }}>
                      <Button
                        size={"sm"}
                        variant="outline-primary"
                        onClick={() => {
                          setOpeneModal(true);
                        }}
                      >
                        Rechazar
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <blockquote className="blockquote mb-0">
                  <footer style={{ fontSize: "11px" }}>
                    Tiene a su disposición las Oficinas de Participación
                    Ciudadana a nivel nacional; el correo electrónico{" "}
                    <strong> habeasdata@ecopetrol.com.co</strong>; y los
                    teléfonos, en Bogotá D.C.,{" "}
                    <strong>(57+1) 234 5000 - opción 7</strong>; y a nivel
                    nacional, <strong>018000918418</strong>
                  </footer>
                </blockquote>
              </Card.Footer>
            </Card>
          </CardGroup>
        </Col>
        <Col xs={2}></Col>
      </Row>
      <ModalCustom
        show={OpeneModal}
        texts={{
          title: "Ley de proteccion de datos personales",
          subTitle:
            "Si no acepta el tratamiento de datos de Ecopetrol no podrá acceder a esta página",
        }}
        handleClose={() => {
          setOpeneModal(false);
        }}
        hideButtons={true}
        size={"sm"}
        // modificateCss={{maxWidth:'24%'}}
        inputCustom={
          <ApproveDeclineTDP
            button1={
              <Button
                size={"sm"}
                variant="primary"
                onClick={() => {
                  saveApprove({ approve: false });
                  setOpeneModal(false);
                }}
              >
                Aceptar
              </Button>
            }
            button2={
              <Button
                size={"sm"}
                variant="outline-primary"
                onClick={() => {
                  setOpeneModal(false);
                }}
              >
                Cancelar
              </Button>
            }
          />
        }
      />
    </Container>
  );
};
interface InterfaceApprove {
  approve?: boolean;
}
