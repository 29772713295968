import "../../css/head.css";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import logo from "../../Assets/logo_ecopetrol.png";
import { getValidAdmin } from "../../Providers/GetTextLaw";
import { ForceLoadPage, getOriginURI } from "../../Utils/ForceLoadPage";

export const Head = ({
  viewHead,
  props = {},
}: InterfaceViewHead): JSX.Element => {
  const [GetNameDisplay, setGetNameDisplay] = useState("");
  const [getFullNameDisplay, setGetFullNameDisplay] = useState("");
  useEffect(() => {
    const validObj = Object.keys(props);
    if (validObj.length > 0) {
      const { userInfo, getTokent } = props;
      if (userInfo.id !== undefined) {
        getValidAdmin(userInfo.id, `${getOriginURI()}`, getTokent).then(
          (result) => {
            if (!result.data.data) {
              ForceLoadPage({ url: "/" });
            }
          }
        );
      }
    }
  }, [props]);

  useEffect(() => {
    const { isAuth, userInfo } = props;
    if (isAuth) {
      setGetFullNameDisplay(userInfo.displayName);
      setGetNameDisplay(
        `${userInfo.givenName.charAt(0)}${userInfo.surname.charAt(0)}`
      );
    }
  }, [props]);

  const logout = () => {
    const { logout } = props;
    logout();
  };

  return (
    <>
      <div className="head">
        {viewHead ? (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-1"></div>
                <div
                  className="col-sm-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={logo}
                    className={"img-fluid"}
                    alt={"Logotipo ecopetrol"}
                    style={{ width: "33%" }}
                  />
                </div>
                <div className="col-sm-5" style={{ display: "flex" }}>
                  <div className="container-fluid">
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-sm-2">
                        <span
                          style={{
                            width: "89%",
                            marginTop: "45%",
                            fontSize: "36px",
                            display: "block",
                            textAlign: "center",
                            borderRadius: "51px",
                            backgroundColor: "white",
                          }}
                        >
                          {GetNameDisplay}
                        </span>
                      </div>
                      <div
                        className="col-sm-8"
                        style={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          ¡Hola!
                        </span>
                        <span
                          style={{ fontSize: "16px", marginBottom: "12px" }}
                        >
                          {getFullNameDisplay}
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: " space-around",
                          }}
                        >
                          <Button
                            variant="primary"
                            style={{
                              width: "47%",
                              height: "34px",
                              marginBottom: "2px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              logout();
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Cerrar sesión
                            </span>
                          </Button>
                        </span>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="col-sm-12">
                        <div
                          style={{
                            height: "6px",
                            display: "flex",
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <img src={logo} className={"img-fluid"} alt={"Logotipo ecopetrol"} />
        )}
      </div>
    </>
  );
};
interface InterfaceViewHead {
  viewHead?: boolean;
  props?: any;
}
