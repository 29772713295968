import {
  getObtenerListadoDeclaracionTdp,
  getObtenerTratamientoDatosPersonales,
} from "../../../Providers/GetTextLaw";
import { useEffect, useState } from "react";
import { FromDeclaracionTDP } from "./FormDeclaracionTDP";
import { LoadingSpin } from "../../../Layouts/Loading/Loading";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { InterfaceGetServiceText } from "../../../Interface/InterfaceTextLaw";
import { DataGridCustom } from "../../../Components/GridCustom/DataGridCustom";
import withAuthentication from "../../Base/AuthenticatedBase";


export const ContentDeclaracionTDP = ({ props }: any) => {
  const [DataSet, setDataSet] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [GetAppName, setGetAppName] = useState<any>();
  const [idSession, setIdSession] = useState<any>([]);
  const [realoadGrid, setRealoadGrid] = useState<any>([]);
  const [getModificate, setGetModificate] = useState(false);
  const [getData, setGetData] = useState<InterfaceGetServiceText>();


  useEffect(() => {
    const { isAuth, userInfo } = props;
    if (isAuth) {
      setIdSession(userInfo);
    }
  }, [props]);

  useEffect(() => {
    if (realoadGrid !== undefined) {
      if (Object.keys(realoadGrid).length > 0) {
        setLoading(false);
        loadData(realoadGrid);
      }
    }
  }, [realoadGrid]);

  useEffect(() => {
    if (GetAppName !== undefined) {
      if (Object.keys(GetAppName).length > 0) {
        loadData(GetAppName);
      }
    }
  }, [GetAppName]);

  const loadData = (GetAppName: any) => {
    getObtenerListadoDeclaracionTdp(GetAppName.auth.userInfo.id, GetAppName.txt)
      .then((result) => {
        if (result !== undefined) {
          setDataSet(result.data.data);
        }
      })
      .then(() => {
        getObtenerTratamientoDatosPersonales(
          GetAppName.auth.userInfo.id,
          GetAppName.txt
        ).then((result: InterfaceGetServiceText) => {
          if (result !== undefined) {
            setGetData(result);
          }
        });
      });
  };

  const columns = [
    { field: "fechaCreacion", headerName: "Fecha última edición", width: 200 },
    { field: "horaCreacion", headerName: "hora", width: 100 },
    {
      field: "nombresApellidos",
      headerName: "Nombre del último editor",
      width: 350,
    },
    { field: "email", headerName: "Correo electrónico", width: 220 },
    { field: "versionTratamientoDatos", headerName: "Versión", width: 90 },
  ];

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Declaración de tratamiento de datos personales
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={8}>
            <br />
            <FromDeclaracionTDP
              props={props}
              realodGrid={(data: any) => {
                setRealoadGrid(data);
              }}
              changeSelect={(result: string) => {
                setGetAppName(result);
              }}
            />
          </Col>
          <Col xs={2}></Col>
        </Row>
        <Row>
          <Col xs={12}>
            <br />
            <DataGridCustom
              columns={columns}
              dataSet={DataSet}
              title={""}
              key={"dataGridDeclaracion"}
              showSelect={false}
              height={300}
              margin={"0px"}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "10%", marginTop: "0px" }}>
          <Col xs={12}>
            <Card>
              <Card.Header>
                <Form.Control
                  as="textarea"
                  style={{
                    height: "65vh",
                    background: "#F1F2F2",
                    resize: "none",
                  }}
                  defaultValue={getData?.data.data.textoTratamientoDatos || ""}
                  disabled
                />
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </Container>
      <LoadingSpin
        activeSpin={!Loading}
        timeOut={6000}
        redirectState={{ data: true }}
        key={"loading"}
        callback={() => {
          setLoading(true);
          setGetModificate(true);
        }}
      />
    </>
  );
};


export const EnhancedContentDeclaracionTDP = withAuthentication(ContentDeclaracionTDP);