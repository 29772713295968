import { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ModalCustom } from "../../../Components/ModalCustom/ModalCustom";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  getCrearDeclaracionTdp,
  getObtenerListadoAplicaciones,
  getObtenerTratamientoDatosPersonales,
} from "../../../Providers/GetTextLaw";

export const FromDeclaracionTDP = ({
  props,
  changeSelect = () => {},
  realodGrid = () => {},
}: InterfaceFormDeclaracionTDP) => {
  const [Open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [ValueSelect, setValueSelect] = useState("");
  const [GetTextService, setGetTextService] = useState("");
  const [GetVersion, setGetVersion] = useState<any>();
  const [GetDataUser, setGetDataUser] = useState<any>();
  const handleChange = (event: any) => {
    const { value } = event.target;
    setValueSelect(value);
  };

  useEffect(() => {
    if (ValueSelect !== "") {
      const { userInfo } = props;
      const AppSelected: string = ValueSelect.split("|")[1];
      changeSelect({ txt: AppSelected, auth: props });
      setGetDataUser(props);
      getObtenerTratamientoDatosPersonales(userInfo.id, AppSelected).then(
        (result) => {
          setGetTextService(result.data.data.textoTratamientoDatos);
          setGetVersion(result.data.data.version);
        }
      );
    }
  }, [ValueSelect, Open]);

  const [GetLsitApp, setGetLsitApp] = useState([]);
  useEffect(() => {
    getObtenerListadoAplicaciones().then((result) => {
      setGetLsitApp(result.data.data);
    });
  }, []);

  const [GetTextModificate, setGetTextModificate] = useState<any>();
  const handleSave = () => {
    const { displayName, email, userInfo, getTokent } = GetDataUser;

    const AppSelected: string = ValueSelect.split("|")[1];
    const data = {
      commondRequestDto: {
        usuarioId: userInfo.id,
        nombreAplicacion: AppSelected,
      },
      textoTratamientoDatos: GetTextModificate.target.value,
      versionTratamientoDatos: GetVersion + 1,
      aplicacionId: 1,
      nombresApellidos: displayName,
      email: email,
    };
    getCrearDeclaracionTdp(data, getTokent).then((result: any) => {
      realodGrid({ txt: AppSelected, auth: props });
      setOpen(false);
    });
  };

  const valueText = (item: any) => {
    setGetTextModificate(item);
  };

  return (
    <Container>
      <Row>
        <Col xs={4}>
          <Box>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "12px" }}
              >
                Selecione una aplicación
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Selecione una aplicación"
                size="small"
                name={"aplicaction"}
                key={"listApp"}
                value={ValueSelect ?? ""}
                onChange={(event) => {
                  handleChange(event);
                }}
              >
                {GetLsitApp.map((result: any) => {
                  return (
                    <MenuItem
                      value={`${result.id}|${result.nombre}`}
                      key={result.id}
                    >
                      {result.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Col>
        <Col xs={4}>
          <Button
            variant="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <ModeEditIcon />
            Editar declaración
          </Button>
        </Col>
      </Row>
      <ModalCustom
        show={Open}
        texts={{
          title: "Editar la ley de protección de datos personales",
          subTitle:
            "Puedes copiar y pegar la información que deseas editar. recuerda que la caja de texto se puede expandir arrastrando su extremo inferior derecho hacia abajo.",
          text: GetTextService,
        }}
        handleSave={handleSave}
        handleClose={handleClose}
        valueText={(text: any) => {
          valueText(text);
        }}
      />
    </Container>
  );
};
interface InterfaceFormDeclaracionTDP {
  props: any;
  changeSelect?: Function;
  realodGrid?: Function;
}
