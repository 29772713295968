import { useState } from "react";
import { TextField } from "@mui/material";
import { Button, Col, Container, Row } from "react-bootstrap";
import { getCrearAdministrador } from "../../../Providers/GetTextLaw";
import { ModalCustom } from "../../../Components/ModalCustom/ModalCustom";
import { ForceLoadPage, getOriginURI } from "../../../Utils/ForceLoadPage";

export const FormAdmin = ({ props, dataList }: any): JSX.Element => {
  const [Error, setError] = useState(false);
  const [GetEmail, setGetEmail] = useState<any>();
  const [GetMailUsers, setGetMailUsers] = useState({});
  const [ViewButton, setViewButton] = useState(false);
  const [OpenModal, setOpenModal] = useState<any>({});
  const validEmail = () => {
    try {
      const { getValidEmail } = props;
      if (GetEmail !== undefined) {
        const { value } = GetEmail.target;
        let moreInfo = dataList.find((val: any) => {
          return val.email === value;
        });
        if (moreInfo !== undefined) {
          setOpenModal({
            error: true,
            msg: "El usuario que deseas agregar ya existe como administrador",
          });
        } else if (value !== "") {
          const resultValid = getValidEmail(value);
          resultValid
            .then((result: any) => {
              setGetMailUsers(result);
              setError(false);
              setViewButton(true);
            })
            .catch((error: any) => {
              setError(true);
            });
        }
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };
  const saveNewAdmin = () => {
    try {
      const { userInfo, getTokent } = props;
      const { displayName, id, userPrincipalName }: any = GetMailUsers;
      const data = {
        commondRequestDto: {
          usuarioId: userInfo.id,
          nombreAplicacion:`${getOriginURI()}`,
        },
        usuarioId: id,
        nombresApellidos: displayName,
        email: userPrincipalName,
      };
      getCrearAdministrador(getTokent, `${getOriginURI()}`, data)
        .then((result) => {
          ForceLoadPage({ url: "/admin/administradores" });
        })
        .catch((error) => {
          setError(true);
          setOpenModal({
            error: true,
            msg: "Este usuario ya es administrador",
          });
        });
    } catch (error) {
      setError(true);
      setOpenModal({
        error: true,
        msg: "Este usuario ya es administrador",
      });
    }
  };
  return (
    <Container>
      <br />
      <Row>
        <Col xs={5}>
          <TextField
            size="small"
            error={Error}
            helperText={
              (Error && "Este usuario no puede ser administrador") || ""
            }
            id="outlined-error"
            label="Ingrese el correo del nuevo administrador"
            fullWidth
            onChange={(str: any) => {
              setGetEmail(str);
            }}
          />
        </Col>
        <Col xs={3}>
          <Button
            variant="link"
            onClick={() => {
              validEmail();
            }}
          >
            Validar correo
          </Button>
        </Col>
        <Col xs={2}>
          {ViewButton ? (
            <Button
              onClick={() => {
                saveNewAdmin();
              }}
            >
              Guardar
            </Button>
          ) : (
            <></>
          )}
        </Col>
        <Col xs={2}>
          <Button
            variant={"outline-primary"}
            onClick={() => {
              ForceLoadPage({ url: "/admin/administradores" });
            }}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
      <ModalCustom
        show={OpenModal.error}
        texts={{ title: "Error", text: "", subTitle: OpenModal.msg }}
        hideButtons={true}
        size={"sm"}
        inputCustom={
          <>
            <br />
            <Button
              size="sm"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Salir
            </Button>
          </>
        }
        handleClose={() => {}}
      />
    </Container>
  );
};
