export const ForceLoadPage = ({ url }: type): void => {
  window.location.href = url;
};

export const getOriginURI = (): string | boolean => {
  const { origin } = window.location;
  let uri = origin.split("//");
  if (uri.length > 0) {
    return uri[1];
  } else {
    return false;
  }
};
interface type {
  url: string;
}
