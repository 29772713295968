import { AuthMsal } from "./Pages/Auth";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReportTDP } from "./Pages/Admin/reportTDP/ReportTDP";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AdminsTDP } from "./Pages/Admin/administradoresTDP/adminTDP";
import { DeclaracionContentTDP } from "./Pages/Admin/declaracionTDP/DeclaracionContentTDP";
import { useEffect, useState } from "react";

function App() {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const allKeys = Object.keys(localStorage);
      const idTokenKeys = allKeys?.filter((key) => key.endsWith("idtoken"));
      const tokenFromSessionStorage = idTokenKeys?.map((key) => localStorage?.getItem(key))[0];
      const isValid = tokenFromSessionStorage !== null && tokenFromSessionStorage !== undefined && tokenFromSessionStorage !== '';
      setIsTokenValid(isValid);
      setVerificationComplete(true);
    };
    checkTokenValidity();
  }, []);

  if (!verificationComplete) {
    return <div>Cargando...</div>;
  }
  
  return (
    <Router>
        <Routes>
          <Route path="*" element={<AuthMsal />} />
          <Route path="/" element={<AuthMsal />} />
          <Route path="/admin" element={isTokenValid ? <ReportTDP /> : <Navigate replace to={"/"} />} />
          <Route path="/admin/reporte" element={isTokenValid ? <ReportTDP /> : <Navigate replace to={"/"} />} />
          <Route path="/admin/declaracion" element={isTokenValid ? <DeclaracionContentTDP /> : <Navigate replace to={"/"} />} />
          <Route path="/admin/administradores" element={isTokenValid ? <AdminsTDP /> : <Navigate replace to={"/"} />} />
        </Routes>
    </Router>
  );
}

export default App;