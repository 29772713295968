import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { useEffect, useState } from "react";
import "../../../css/styleBoostrapCustom.css";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Col, Container, Row } from "react-bootstrap";
import { getObtenerListadoAplicaciones } from "../../../Providers/GetTextLaw";
import { InterfaceFilters } from "../../../Interface/InterfaceComponentDataGrid";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { TranslateDatePicker } from "../Translate/TranslateDataPicker";

export const FiltersDataGrid = ({
  title,
  filter,
  search,
}: InterfaceFilters): JSX.Element => {
  const [age, setAge] = useState("");
  const [age1, setAge1] = useState("");
  const [GetFilters, setGetFilters] = useState({
    dateInitial: [""],
    dateFinally: [""],
    aplicacionName: [""],
    aproboTratamiento: [""],
  });

  
  const setValues = (item: any) => {
    setGetFilters({
      ...GetFilters,
      ["dateInitial"]: item.fechaInicial,
      ["dateFinally"]: item.fechaFinal,
    });
  };

  const Filteer = () => {
    filter(GetFilters);
  };

  const handleChange = (event: any) => {
    setAge(event.target.value);
    const { value, name } = event.target;
    setGetFilters({ ...GetFilters, [name]: value });
  };
  const handleChange1 = (event: any) => {
    setAge1(event.target.value);
    const { value, name } = event.target;
    setGetFilters({ ...GetFilters, [name]: value });
  };
  const [GetLsitApp, setGetLsitApp] = useState([]);
  useEffect(() => {
    getObtenerListadoAplicaciones().then((result) => {
      setGetLsitApp(result.data.data);
    });
  }, []);
  const [getDataSearch, setgetDataSearch] = useState("");

  return (
    <>
      <Container style={{ margin: "0px", padding: "0px" }}>
        <Row>
          <Col xs={12}>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              {title}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>Filtrar por:</Col>
        </Row>
        <Row>
          <Col>
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Box>
              <FormControl fullWidth>
                <DateRangePicker
                  onApply={(event, picker) => {
                    picker.element.val(
                      picker.startDate.format("DD/MM/YYYY") +
                        " - " +
                        picker.endDate.format("DD/MM/YYYY")
                    );
                    setValues({
                      fechaInicial: picker.startDate.format("DD/MM/YYYY"),
                      fechaFinal: picker.endDate.format("DD/MM/YYYY"),
                    });
                  }}
                  onCancel={(even, picker) => {
                    // picker.element.val("");
                  }}
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: TranslateDatePicker,
                  }}
                >
                  <input
                    type="text"
                    className="form-control col-4"
                    placeholder="Fecha"
                    defaultValue=""
                  />
                </DateRangePicker>
              </FormControl>
            </Box>
          </Col>
          <Col xs={2}>
            <Box>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px", top: "-16%" }}
                >
                  Seleccion Estado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Selecione un estado"
                  size="small"
                  onChange={handleChange}
                  name={"aproboTratamiento"}
                  key={"listSate"}
                >
                  <MenuItem value={1}>Aprobado</MenuItem>
                  <MenuItem value={2}>Rechazado</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Col>
          <Col xs={2}>
            <Box>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px", top: "-16%" }}
                >
                  Seleccion Aplicación
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age1}
                  label="Selecione una aplicación"
                  size="small"
                  onChange={handleChange1}
                  key={"listApp"}
                  name={"aplicacionName"}
                >
                  {GetLsitApp.map((result: any) => {
                    return (
                      <MenuItem
                        value={`${result.id}|${result.nombre}`}
                        key={result.id}
                      >
                        {result.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Col>
          <Col xs={2}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="primary"
                onClick={() => {
                  Filteer();
                }}
              >
                Filtrar
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Limpiar
              </Button>
            </div>
          </Col>

          <Col xs={3}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(item) => {
                  setgetDataSearch(item.target.value);
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  search(getDataSearch);
                }}
              >
                <SearchIcon style={{ fontSize: "26px", color: "white" }} />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
