import { Col, Container, Row } from "react-bootstrap";

export const ApproveDeclineTDP = ({
  button1,
  button2,
}: InterfacePropsInputs): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col xs={12}><br /></Col>
      </Row>
      <Row>
        <Col xs={6}>{button1}</Col>
        <Col xs={6}>{button2}</Col>
      </Row>
    </Container>
  );
};
interface InterfacePropsInputs {
  button1: JSX.Element;
  button2: JSX.Element;
}
