import "../../App.css";
import { useCookies } from "react-cookie";
import { InterfaceViewRedirect } from "../../Interface/InterfaceLoading";
import { useNavigate } from "react-router-dom";

export const ProteccionDatosPersonales = ({
  aproveLPDP,
}: InterfaceViewRedirect): JSX.Element => {
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  setCookie("tdpsuccess", "RUNQU1VDQ0VTUw==", {
    path: "/",
    maxAge: 3600 * 4,
  });

  window.location.reload();
  navigate("/admin");
  return <></>;
};



