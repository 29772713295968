import { useState } from "react";
import { EnhancedContenTDP } from "./ContenTDP";
import { Container } from "react-bootstrap";
import { Head } from "../../../Layouts/Header/Head";
import { NavBar } from "../../../Layouts/NavBar/NavBar";
import { Footer } from "../../../Layouts/Footer/Footer";
import { AuthConten } from "../../../Providers/AuthConten";

export const ReportTDP = () => {
  const [stateUser, setStateUser] = useState<any>({});
  const {isAuth} = stateUser;
  return (
    <>
      <AuthConten
        key={"AuthMsal"}
        login={() => {}}
        userState={(user: any) => {
          setStateUser(user);
        }}
      />
      <Head viewHead={true} props={stateUser} />
      <Container>
        <NavBar activiti={1} />
      </Container>
      <EnhancedContenTDP props={stateUser} isAuth={isAuth}/>
      <Footer />
    </>
  );
};
