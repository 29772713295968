import {
  getObtenerAdministradores,
  setDeleteAdmin,
} from "../../../Providers/GetTextLaw";
import { FormAdmin } from "./FormAdmin";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ModalCustom } from "../../../Components/ModalCustom/ModalCustom";
import { ForceLoadPage, getOriginURI } from "../../../Utils/ForceLoadPage";
import { DataGridCustom } from "../../../Components/GridCustom/DataGridCustom";
import withAuthentication from "../../Base/AuthenticatedBase";

export const CreateAdminTDP = ({ props }: any): JSX.Element => {
  const [Open, setOpen] = useState(false);
  const [getListAdmin, setListAdmin] = useState<any>([]);
  const [GetText, setGetText] = useState("");
  const [openModalValidDelete, setOpenModalValidDelete] = useState(false);
  const [OpenError, setOpenError] = useState(false);
  const [setItemDelete, setStItemDelete] = useState<any>();
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getObtenerAdministradores().then((result: resultService) => {
      if (result !== undefined) {
        result.data.data.map((value: any, key) => {
          result.data.data[key].id = key;
        });
        setListAdmin(result.data.data);
      }
    });
  }, []);

  const removeAdmin = (userAdmin: any) => {
    try {
      const { row } = userAdmin;
      const { userInfo, getTokent } = props;
      const data = {
        commondRequestDto: {
          usuarioId: userInfo.id,
          nombreAplicacion: `${getOriginURI()}`,
        },
        usuarioId: row.usuarioId,
        nombresApellidos: row.nombresApellidos,
        email: row.email,
      };
      setDeleteAdmin(getTokent, data).then((result) => {
        if (result.data.data) {
          ForceLoadPage({ url: "/admin/administradores" });
        }
      });
    } catch (error) {}
  };

  const handleSave = () => {
  };
  const [ErrorText, setErrorText] = useState<any>({});
  const columns = [
    {
      field: "nombresApellidos",
      headerName: "Nombre del último editor",
      width: 350,
    },
    { field: "email", headerName: "Correo electrónico", width: 250 },
    { field: "fechaCreacion", headerName: "Fecha registro", width: 200 },
    { field: "horaCreacion", headerName: "Hora registro", width: 150 },
    {
      field: "actions",
      headerName: "Eliminar",
      width: 70,
      renderCell: (val: any) => {
        return (
          <DeleteIcon
            style={{ color: "#0E0C5A", alignContent: "center" }}
            onClick={() => {
              if (val.api.getRowsCount() > 1) {
                const { userInfo } = props;
                const { email } = val.row;
                if (userInfo.userPrincipalName === email) {
                  setErrorText({
                    msg: "No es posible eliminar asi mismo",
                    open: true,
                  });
                } else {
                  setOpenModalValidDelete(true);
                  setStItemDelete(val);
                }
              } else {
                setOpenError(true);
              }
            }}
          />
        );
      },
    },
  ];
  return (
    <>
      <Container style={{ marginBottom: "3%" }}>
        <Row>
          <Col xs={12}>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Edición de administradores tratamiento de datos personales
            </span>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <Button
              size={"sm"}
              variant="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Agregar nuevo administrador
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <span>
              Para eliminar a un administrador registrado seleccione el icono de
              eliminar
            </span>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <DataGridCustom
              columns={columns}
              dataSet={getListAdmin}
              title={""}
              key={"dataGridDeclaracion"}
              showSelect={false}
              height={300}
            />
          </Col>
        </Row>
        <ModalCustom
          show={openModalValidDelete}
          size={"sm"}
          key={"modalDelete"}
          texts={{
            title: "Advertencia",
            text: "",
            subTitle: "¿Está seguro de eliminar el registro?",
          }}
          inputCustom={
            <>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <br />
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    removeAdmin(setItemDelete);
                    setOpenModalValidDelete(false);
                  }}
                >
                  Aceptar
                </Button>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    setOpenModalValidDelete(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </>
          }
          hideButtons={true}
          handleClose={() => {
            setOpenModalValidDelete(false);
          }}
        />
        <ModalCustom
          show={OpenError || ErrorText.open}
          size={"sm"}
          key={"modalError"}
          texts={{
            title: "Error",
            text: "",
            subTitle: "Lo sentimos, usted no puede eliminar este registro",
          }}
          inputCustom={
            <>
              <br />
              <Button
                size="sm"
                onClick={() => {
                  setErrorText({ open: false });
                  setOpenError(false);
                }}
              >
                Cerrar
              </Button>
            </>
          }
          hideButtons={true}
          handleClose={() => {
            setOpenError(false);
          }}
        />
        <ModalCustom
          show={Open}
          texts={{
            title: "Agrega nuevo administrador",
            subTitle: "Otorga permisos de administrador",
          }}
          inputCustom={<FormAdmin props={props} dataList={getListAdmin} />}
          headButtons={
            <Button
              size={"sm"}
              variant="primary"
              style={{ position: "absolute", right: "2%" }}
              onClick={() => {
                handleClose();
              }}
            >
              Cerrar
            </Button>
          }
          handleClose={handleClose}
          hideButtons={true}
          valueText={(txt: string) => {
            setGetText(txt);
          }}
          handleSave={handleSave}
        />
      </Container>
    </>
  );
};
interface resultService {
  data: {
    data: Array<any>;
  };
}

export const EnhancedCreateAdminTDP = withAuthentication(CreateAdminTDP);