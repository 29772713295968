import "../App.css";
import "../css/viewBackground.css";
import { Head } from "../Layouts/Header/Head";
import { Footer } from "../Layouts/Footer/Footer";
import { EvenDispatch } from "./ProteccionDatosPersonales/EvenDispatch";

export const AuthMsal = () => {
  return (
    <>
      <div className="fondo">
        <Head />
        <EvenDispatch />
        <Footer />
      </div>
    </>
  );
};
