import { UserAgentApplication } from "msal";
import { useEffect, useState } from "react";
import { config } from "../Config/config";
import { getMailUser, getUserDetails } from "../Services/GraphService";

export const AuthConten = ({
  login = () => {},
  userState = () => {},
  getValidEmail = () => {},
}: InterfaceAuthLogin) => {
  const [userAgentApplication, setUserAgentApplication] =
    useState<UserAgentApplication>();
  const [accessTokenId, setaccessTokenId] = useState<string>("");
  const [state, setState] = useState({
    email: "",
    isAuth: false,
    userInfo: {},
    getTokent: "",
    displayName: "",
    logout: () => {},
    getValidEmail: (mail: string) => {},
  });

  useEffect(() => {
    const classMsal = new UserAgentApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    });

    classMsal
      .acquireTokenSilent({
        scopes: config.scopes,
      })
      .then((tokenIdSession) => {
        const { accessToken } = tokenIdSession;
        setaccessTokenId(accessToken);
      })
      .catch(() => {
        classMsal.loginRedirect({
          scopes: config.scopes,
        });
      });
    setUserAgentApplication(classMsal);
  }, []);

  useEffect(() => {
    if (accessTokenId) {
      const user = getUserDetails(accessTokenId);
      user.then((resUser) => {
        setState({
          isAuth: true,
          email: resUser.mail || resUser.userPrincipalName,
          getTokent: accessTokenId,
          userInfo: { ...resUser },
          displayName: resUser.displayName,
          logout: () => {
            userAgentApplication?.logout();
          },
          getValidEmail: (mail: string) => {
            return getMailUser(accessTokenId, mail);
          },
        });
      });
    }
  }, [accessTokenId]);

  useEffect(() => {
    userState(state);
  }, [state]);

  return <></>;
};

interface InterfaceAuthLogin {
  login?: Function;
  userState?: Function;
  getValidEmail?: Function;
}
