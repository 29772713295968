import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import "../../css/Admin/Modal/styleModalCustom.css";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import '../../css/styleBoostrapCustom.css'
export const ModalCustom = ({
  show,
  hide = () => {},
  texts,
  handleClose,
  handleSave = () => {},
  valueText = "",
  inputCustom,
  headButtons,
  hideButtons = false,
  size = "lg",
  modificateCss = {},
}: IterfaceModalCustom) => {
  return (
    <Modal
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "27px !importan", ...modificateCss }}
    >
      <Modal.Header
        style={{
          padding: "10px",
          background: "#2E2BAB",
          color: "white",
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Modal.Title>
        </Modal.Title>
        <div
          style={{
            display: "flex ",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ justifyContent: "space-between", display: "contents" }}>
            {texts.title}
            <ReportGmailerrorredIcon style={{}} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <span style={{ fontSize: "16px" }}>{texts.subTitle}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {inputCustom ? (
                inputCustom
              ) : (
                <Form.Control
                  as="textarea"
                  onChange={(item) => {
                    valueText(item);
                  }}
                  style={{
                    margin: "0px 0px",
                    padding: "30px",
                    height: "40vh",
                    background: "#F1F2F2",
                  }}
                  defaultValue={texts.text}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      {!hideButtons && (
        <Modal.Footer style={{ margin: "10px" }}>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

interface IterfaceModalCustom {
  show: boolean;
  hide?: any;
  texts: texts;
  handleClose: any;
  handleSave?: any;
  valueText?: any;
  inputCustom?: JSX.Element;
  headButtons?: JSX.Element;
  hideButtons?: Boolean;
  size?: "lg" | "sm";
  modificateCss?: {};
}

interface texts {
  title: string;
  subTitle: string;
  text?: string | undefined;
}
