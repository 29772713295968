import "../../../css/Admin/dataGrid.css";
import { useEffect, useState } from "react";
import { getReportDP } from "../../../Providers/GetTextLaw";
import { IconState } from "../../../Components/GridCustom/IconState";
import { DataGridCustom } from "../../../Components/GridCustom/DataGridCustom";
import withAuthentication from "../../Base/AuthenticatedBase";

export const ContenTDP = () => {
  const [dataSet, setDataSet] = useState([]);
  useEffect(() => {
    getReportDP().then((result) => {
      setDataSet(result.data.data);
    });
  }, []);
  const columns = [
    { field: "nombreUsuario", headerName: "Nombre y apellidos", width: 300 },
    { field: "email", headerName: "Correo electrónico", width: 250 },
    { field: "aplicacionName", headerName: "Aplicación", width: 150 },
    {
      field: "aproboTratamiento",
      headerName: "Estado",
      width: 110,
      renderCell: (cellValues: any) => {
        if (cellValues.formattedValue === false) {
          return <IconState label={cellValues.value} color={"#F02A17"} />;
        } else if (cellValues.formattedValue === true) {
          return <IconState label={cellValues.value} color={"#26FF1C"} />;
        }
      },
    },
    { field: "fechaCreacion", headerName: "Fecha", width: 95 },
    { field: "horaCreacion", headerName: "Hora", width: 80 },
    { field: "versionTratamientoDatos", headerName: "Versión", width: 65 },
  ];

  return (
    <DataGridCustom
      dataSet={dataSet}
      columns={columns}
      title={"Reporte tratamiento de datos personales"}
      showPaginate={true}
    />
  );
};

export const EnhancedContenTDP = withAuthentication(ContenTDP);