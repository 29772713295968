import { ViewPDP } from "./ViewPDP";
import {
  getObtenerTratamientoDatosPersonales,
  getEstaUsuarioAutorizadoIngresarAplicacion,
} from "../../Providers/GetTextLaw";
import {
  validUser,
  InterfaceArrayState,
} from "../../Interface/InterfaceTextLaw";
import { useEffect, useState } from "react";
import { AuthConten } from "../../Providers/AuthConten";
import { getOriginURI } from "../../Utils/ForceLoadPage";
import { LoadingSpin } from "../../Layouts/Loading/Loading";
import { ProteccionDatosPersonales } from "../ProteccionDatosPersonales/GetProteccionDatosPersonales";

export const EvenDispatch = (): JSX.Element => {
  const [stateUser, setStateUser] = useState<any>({});
  const [ViewLoading, setViewLoading] = useState(true);
  const [stateUserLDP, setStateUserLDP] = useState<any>({});
  const [GetTextsLPDP, setGetTextsLPDP] = useState<getTexts>();
  const [LoadComponent, setLoadComponent] = useState<JSX.Element>();
  
  const getTextoLeyPDP = (idSesion: string, user: any, Auth: boolean) => {
    getEstaUsuarioAutorizadoIngresarAplicacion(
      idSesion,
      `${getOriginURI()}`
    ).then((result: validUser) => {
      if (result !== undefined) {
        setStateUserLDP({
          state: result?.data.data,
          session: idSesion,
          user: user,
          auth: Auth,
        });
      }
    });
  };

  useEffect(() => {
    const { isAuth, userInfo } = stateUser;
    if (isAuth) {
      getObtenerTratamientoDatosPersonales(
        userInfo.id,
        `${getOriginURI()}`
      ).then((result: InterfaceArrayState) => {
        result.data.data.user = stateUser;
        setGetTextsLPDP(result.data.data);
      });
      getTextoLeyPDP(userInfo.id, userInfo, isAuth);
      return;
    }
  }, [stateUser]);

  return (
    <>
      <AuthConten
        key={"AuthMsal"}
        login={(data: any) => {}}
        userState={(user: any) => {
          setStateUser(user);
        }}
      />
      <LoadingSpin
        activeSpin={ViewLoading}
        redirectState={{ data: true }}
        timeOut={3000}
        callback={(result: boolean) => {
          const { state } = stateUserLDP;
          if (state && state !== undefined) {
            setLoadComponent(<ProteccionDatosPersonales aproveLPDP={true} />);
            setViewLoading(false);
          } else if (state === false && state !== undefined) {
            setLoadComponent(
              <ViewPDP
                text={GetTextsLPDP?.textoTratamientoDatos}
                version={GetTextsLPDP?.version}
                nameApp={GetTextsLPDP?.nombre}
                dataUser={GetTextsLPDP?.user}
                logout={() => {}}
                loading={() => {
                  setViewLoading(true);
                }}
              />
            );
            setViewLoading(false);
          }
        }}
      />
      {LoadComponent}
    </>
  );
};
interface getTexts {
  id: number;
  nombre: string;
  textoTratamientoDatos: string;
  version: number;
  user?: any;
}
