import axios from "axios";

export const setService = (idToken: string = "") => {
  const key: string = `${process.env.REACT_APP_API_KEY}`;
  const baseURL: string = `${process.env.REACT_APP_BASE_URL}`;
  const result = axios.create({
    baseURL: baseURL,
    timeout: 25000,
    headers: {
      process: false,
      "Access-Control-Allow-Origin": "*",
      "Ocp-Apim-Subscription-Key": key,
      Authorization: `Bearer ${idToken}`,
    },
  });
  return result;
};