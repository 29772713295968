export const TranslateDatePicker = {
  format: "DD/MM/YYYY",
  applyLabel: "Aplicar",
  cancelLabel: "Cancelar",
  fromLabel: "DE",
  toLabel: "HASTA",
  firstDay: 1,
  customRangeLabel: "Custom",
  daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sáb"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
};
