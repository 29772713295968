var graph = require("@microsoft/microsoft-graph-client");

function getAuthenticatedClient(accessToken: string) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);
  const user = await client.api("/me").get();
  return user;
}

export async function getPhotoUser(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);
  const photo = await client.api("/me/photo/{64 x 64}");
  return photo;
}

export async function getMailUser(accessToken: string, mail: string) {
  const client = getAuthenticatedClient(accessToken);
  return await client.api(`/users/${mail}`).get();
}
