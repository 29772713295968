import {
  esES,
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import "../../css/Admin/dataGrid.css";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiltersDataGrid } from "./Filters/FilterDataGrid";
import { useStylesDataGrid } from "./DataGridStyle/MakeStyle";
import { InterfaceComponentDataGrid } from "../../Interface/InterfaceComponentDataGrid";

export const DataGridCustom = ({
  title,
  columns,
  dataSet,
  showSelect = true,
  height = 1185,
  showPaginate = false,
  margin = "3%",
}: InterfaceComponentDataGrid): JSX.Element => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ disableToolbarButton: !showSelect, utf8WithBom: true }}
        />
      </GridToolbarContainer>
    );
  };
  const [GetFilters, setGetFilters] = useState<any>({});
  const [FullData, setFullData] = useState<any>([]);
  const [searchFilter, setsearchFilter] = useState("");

  const validFilters = (setString: string) => {
    if (setString == undefined || setString == "") {
      setString = "";
    }
    return setString;
  };

  const toMs = (dateStr: String) => {
    let parts: any = dateStr.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]).getTime();
  };

  useEffect(() => {
    const obj = Object.keys(GetFilters);
    const data = [...dataSet];

    if (obj.length > 0) {
      const app = validFilters(GetFilters.aplicacionName).split("|")[1];
      const fechaFinal = validFilters(GetFilters.dateFinally);
      const fechaInicial = validFilters(GetFilters.dateInitial);
      const estado: any = validFilters(GetFilters.aproboTratamiento);

      let out: any = data.filter((info: any) => {
        if (
          fechaInicial !== "" &&
          fechaFinal !== "" &&
          estado === "" &&
          app === undefined
        ) {
          return (
            toMs(info.fechaCreacion) >= toMs(fechaInicial) &&
            toMs(info.fechaCreacion) <= toMs(fechaFinal)
          );
        } else if (
          fechaInicial !== "" &&
          fechaFinal !== "" &&
          estado !== "" &&
          app === undefined
        ) {
          return (
            toMs(info.fechaCreacion) >= toMs(fechaInicial) &&
            toMs(info.fechaCreacion) <= toMs(fechaFinal) &&
            info.aproboTratamiento === (estado === 1 ? true : false)
          );
        } else if (
          fechaInicial !== "" &&
          fechaFinal !== "" &&
          estado !== "" &&
          app !== undefined
        ) {
          return (
            toMs(info.fechaCreacion) >= toMs(fechaInicial) &&
            toMs(info.fechaCreacion) <= toMs(fechaFinal) &&
            info.aproboTratamiento === (estado === 1 ? true : false) &&
            info.aplicacionName === app
          );
        } else if (
          fechaInicial === "" &&
          fechaFinal === "" &&
          estado !== "" &&
          app === undefined
        ) {
          return info.aproboTratamiento === (estado === 1 ? true : false);
        } else if (
          fechaInicial === "" &&
          fechaFinal === "" &&
          estado === "" &&
          app !== undefined
        ) {
          return info.aplicacionName === app;
        } else if (
          fechaInicial === "" &&
          fechaFinal === "" &&
          estado !== "" &&
          app !== undefined
        ) {
          return (
            info.aproboTratamiento === (estado === 1 ? true : false) &&
            info.aplicacionName === app
          );
        } else if (
          fechaInicial !== "" &&
          fechaFinal !== "" &&
          estado === "" &&
          app !== undefined
        ) {
          return (
            toMs(info.fechaCreacion) >= toMs(fechaInicial) &&
            toMs(info.fechaCreacion) <= toMs(fechaFinal) &&
            info.aplicacionName === app
          );
        }
      });
      setFullData(out);
    }
  }, [GetFilters]);

  useEffect(() => {
    if (searchFilter !== undefined && searchFilter !== "") {
      const data = [...dataSet];
      const out = data.filter(
        (info) =>
          info.nombreUsuario
            .toLowerCase()
            .includes(searchFilter.toLowerCase()) ||
          info.email.toLowerCase().includes(searchFilter.toLowerCase())
      );
      setFullData(out);
    } else {
      setFullData(dataSet);
    }
  }, [searchFilter]);

  useEffect(() => {
    setFullData(dataSet);
  }, [dataSet]);
  const [pageSize, setPageSize] = useState(5);

  const [page, setPage] = useState<any>(1);
  const totalPages: any = 15;
  const handlePages = (updatePage: number) => setPage(updatePage);

  const classes: any = useStylesDataGrid();
  const translate = esES.components.MuiDataGrid.defaultProps.localeText;
  return (
    <Container>
      <Row>
        <Col xs={12}>
          {showSelect && (
            <FiltersDataGrid
              title={title}
              filter={(data: any) => {
                setGetFilters(data);
              }}
              search={(item: any) => {
                setsearchFilter(item);
              }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div
            style={{ height: height, width: "100%", marginBottom: margin }}
            className={classes.root}
          >
            {showPaginate ? (
              <DataGrid
                columns={columns}
                rows={FullData || []}
                pageSize={20}
                rowsPerPageOptions={[20]}
                components={{
                  Toolbar: CustomToolbar,
                }}
                checkboxSelection={showSelect}
                localeText={translate}
              />
            ) : (
              <DataGrid
                columns={columns}
                rows={FullData || []}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: () => <></>,
                }}
                checkboxSelection={showSelect}
                localeText={translate}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
