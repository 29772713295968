import '../../css/footer.css'
export const Footer = (): JSX.Element => {
  return (
    <>
      <div className="footerConteiner">
        <span className='footerText'>Todos los derechos reservados 2022</span>
      </div>
    </>
  );
};
