import { createStyles, makeStyles } from "@mui/styles";
export const useStylesDataGrid: any = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiDataGrid-root": {
        border: "none !important",
      },
      "& .MuiDataGrid-columnHeaders ": {
        color: "#FFF",
        background: "#0E0C5A",
      },
      "& .countPer": {
        marginLeft: "80%",
      },
      "& .Mui-selected": {
        color: "#25277B !important",
        background: "#0500B026 !important",
      },
      "& .MuiInputBase-root": {
        marginTop: "1%",
      },
      "& .MuiTablePagination-displayedRows": {
        marginTop: "4%",
      },
      "& .MuiTablePagination-selectLabel": {
        marginTop: "4%",
      },
    },
    grid: {
      display: "flex",
      flexDirection: "column-reverse",
      textAlign: "center",
    },
  })
);
