import "../../App.css";
import { useEffect, useState } from "react";
import InterfaceLoading from "../../Interface/InterfaceLoading";

export const LoadingSpin = ({
  activeSpin,
  redirectState,
  timeOut = 1000,
  callback = () => {
    return;
  },
}: InterfaceLoading): JSX.Element => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(activeSpin);
    setTimeout(() => {
      setActive(false);
      callback(redirectState?.data);
    }, timeOut);
  }, [activeSpin, redirectState, timeOut, callback]);
  return (
    <>
      {active && (
        <div className="contenLoading">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};
