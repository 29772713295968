import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "../../css/navBar.css";
export const NavBar = ({ activiti }: navBar): JSX.Element => {
  const [colorSelected, setColorSelected] = useState({});
  const [Page1, setPage1] = useState(false);
  const [Page2, setPage2] = useState(false);
  const [Page3, setPage3] = useState(false);
  useEffect(() => {
    setColorSelected({
      borderBottomStyle: "solid",
      borderBottomColor: "#2b3284",
    });
    switch (activiti) {
      case 1:
        setPage1(true);
        break;
      case 2:
        setPage2(true);
        break;
      case 3:
        setPage3(true);
        break;
    }
  }, [activiti]);

  return (
    <>
      <Navbar
        className="justify-content-center"
        style={{ bottom: "20px", backgroundColor: "red !important" }}
      >
        <Nav.Item style={Page1 ? colorSelected : {}}>
          <Nav.Link
            href="/admin/reporte"
            style={{ width: "100%", textAlign: "center", color: "#7b7878" }}
          >
            Reporte <br />
            de tratamiento de datos personales
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={Page2 ? colorSelected : {}}>
          <Nav.Link
            href="/admin/declaracion"
            style={{ width: "100%", textAlign: "center", color: "#7b7878" }}
          >
            Declaración <br />
            de tratamiento de datos personales
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={Page3 ? colorSelected : {}}>
          <Nav.Link
            href="/admin/administradores"
            style={{ width: "100%", textAlign: "center", color: "#7b7878" }}
          >
            Administradores <br />
            de tratamiento de datos personales
          </Nav.Link>
        </Nav.Item>
      </Navbar>
    </>
  );
};

interface navBar {
  activiti?: number;
}
